import { LectaIconService } from './services/lecta-icon.service';
import { addIconsToService } from './lecta-icon.helper';
import { NgModule } from '@angular/core';

@NgModule()
export class LectaIconRepositoryModule {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  private mediumSvgContext = import.meta.webpackContext('./images/medium', {
    recursive: false,
    regExp: /^[^./].*svg$/,
    mode: 'sync',
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  private svgContext = import.meta.webpackContext('./images/', {
    recursive: true,
    regExp: /^[^./].*svg$/,
    mode: 'sync',
  });

  constructor(private iconService: LectaIconService) {
    addIconsToService(this.mediumSvgContext, this.iconService, 'medium');
    addIconsToService(this.svgContext, this.iconService);
  }
}
