import { Injectable } from '@angular/core';
import { MetaService } from './meta.service';
import { HttpRedirectStatusState, PrerenderState } from '../interfaces';
import _parseISO from 'date-fns/parseISO';
import { Store } from 'core/store';
import { catchAndConvertHttpError } from 'meta/utils/operators/catch-and-convert-http-error.ts';
import { MonoTypeOperatorFunction } from 'rxjs';
import { isRedirectStatusState } from 'meta/utils/type-guard';

interface PrerenderMetaHandler<T extends PrerenderState = PrerenderState> {
  isFit(state: T): boolean;

  setState?(metaService: MetaService, state: T): void;

  clearState?(metaService: MetaService, state: T): void;
}

const baseStateHandler: PrerenderMetaHandler = {
  isFit: (): boolean => true,
  setState: (metaService, state): void => {
    metaService.update([{ name: 'prerender-status-code', content: `${state.httpStatus}` }]);
    if (state.data?.lastModified) {
      const date = _parseISO(state.data.lastModified);
      metaService.addTag({ name: 'prerender-header', content: `Last-Modified: ${date.toUTCString()}` });
    }
  },
  clearState: (metaService, state): void => {
    metaService.removeTagsBy('name', [{ name: 'prerender-status-code' }]);
    if (state.data?.lastModified) {
      const date = _parseISO(state.data?.lastModified);
      const tagSelector = metaService.createMetaQuerySelector([
        ['name', 'prerender-header'],
        ['content', `Last-Modified: ${date.toUTCString()}`],
      ]);
      metaService.remove([tagSelector]);
    }
  },
};

const redirectStateHandler: PrerenderMetaHandler<HttpRedirectStatusState> = {
  isFit: isRedirectStatusState,
  setState: (metaService, state): void => {
    metaService.update([{ name: 'prerender-header', content: `Location: ${state.data?.redirectLocation}` }]);
  },
  clearState: (metaService, state): void => {
    metaService.remove([
      metaService.createMetaQuerySelector([
        ['name', 'prerender-header'],
        ['content', `Location: ${state.data?.redirectLocation}`],
      ]),
    ]);
  },
};

@Injectable({
  providedIn: 'root',
})
export class PrerenderMetaService {
  private handlers: PrerenderMetaHandler[];

  private store = new Store({ prerenderState: undefined as PrerenderState | undefined });
  prerenderState$ = this.store.select('prerenderState');

  constructor(private metaService: MetaService) {
    this.handlers = [baseStateHandler, redirectStateHandler];
  }

  setPrerenderState<T extends PrerenderState = PrerenderState>(state: T): void {
    if (state === this.store.get('prerenderState')) {
      return;
    }
    this.handlers.forEach(handler =>
      handler.setState && handler.isFit(state) ? handler.setState(this.metaService, state) : undefined,
    );
    this.store.updateFields({ prerenderState: state });
  }

  clearPrerenderState<T extends PrerenderState = PrerenderState>(state: T): void {
    this.handlers.forEach(handler =>
      handler.clearState && handler.isFit(state) ? handler.clearState(this.metaService, state) : undefined,
    );
    this.store.updateFields({ prerenderState: undefined });
  }

  catchHttpError<T>(): MonoTypeOperatorFunction<T> {
    return catchAndConvertHttpError<T>(prerenderState => this.setPrerenderState(prerenderState));
  }
}
