import { Inject, Injectable } from '@angular/core';
import { merge, Observable, of, throwError, timer } from 'rxjs';
import { LectaFileApiService } from 'core/file/services/lecta-file-api.service';
import {
  LectaAwsFileUploadEvent,
  LectaAwsFileUploadEventMultiple,
  LectaFileAwsUploadResult,
  LectaFileAwsUploadResultResult,
  LectaFileConfig,
  LectaFileCreateUploadIntentionResult,
  LectaFileImageSize,
  LectaImageSizeDataResponse,
} from 'core/file/lecta-file.interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, mergeMap, retryWhen, switchMap } from 'rxjs/operators';
import {
  LECTA_FILE_CONFIG,
  LectaAwsFileUploadEventType,
  LectaFileUploadProcessStepResultType,
} from 'core/file/lecta-file.consts';
import { LectaFileAmazonS3Api } from 'core/file/services/lecta-amazon-s3-api.service';
import { lectaFileEvents } from 'core/file/lecta-events';

@Injectable({ providedIn: 'root' })
export class LectaFileService {
  private endpoint: string;

  constructor(
    @Inject(LECTA_FILE_CONFIG) skysmartFileConfig: LectaFileConfig,
    private skysmartFileApiService: LectaFileApiService,
    private fileAmazonS3Api: LectaFileAmazonS3Api,
  ) {
    this.endpoint = skysmartFileConfig.endpoint;
  }

  getFileUrlById(id: string): string {
    return `${this.endpoint}/api/v1/file/get/${id}`;
  }

  uploadToAwsMultiple(files: File[], scope: string, meta: Object): Observable<LectaAwsFileUploadEventMultiple> {
    const list = files.map((file, index) => {
      return this.uploadToAws(file, scope, meta).pipe(
        map(event => {
          return {
            index,
            ...event,
          };
        }),
      );
    });

    return merge(...list);
  }

  uploadToAws(file: File, scope: string, meta: Object, type: "support_ticket_screenshot" | null = null): Observable<LectaAwsFileUploadEvent> {
    return this.createIntention(scope, file, meta,type).pipe(
      switchMap(({ type, response, responseCode }) => {
        if (type === LectaFileUploadProcessStepResultType.error) {
          return of({ type: LectaAwsFileUploadEventType.error, responseCode });
        }

        const uuid = response!.uuid;

        return this.uploadToAwsDirectly(scope, file, {
          url: response!.formAction,
          form: response!.formFields,
          uuid,
        }).pipe(
          switchMap(({ type }) => {
            return this.reportUploadResult(scope, uuid, type).pipe(
              map(reportUploadResult => {
                // если загрузка (предыдущий шаг) завершилась не успехом - сразу возвращаем ошибку
                // не важно получилось ли отправить результаты на сервер
                if (
                  type === LectaFileUploadProcessStepResultType.error ||
                  reportUploadResult.type === LectaFileUploadProcessStepResultType.error
                ) {
                  return { type: LectaAwsFileUploadEventType.error, responseCode: reportUploadResult.responseCode };
                }

                return { type: LectaAwsFileUploadEventType.complete, data: { uuid } };
              }),
            );
          }),
        );
      }),
    );
  }

  deleteFile(fileId: string): Observable<void> {
    return this.skysmartFileApiService.deleteFile(fileId);
  }

  getImageSizeData(id: string, size: LectaFileImageSize): Observable<LectaImageSizeDataResponse> {
    return this.skysmartFileApiService.getImageSizeData(id, size);
  }

  getImageSizeUrlWithFallbackToOriginal(fileId: string, size: LectaFileImageSize): Observable<string> {
    return this.getImageSizeData(fileId, size).pipe(
      map(response => {
        return response.url ?? this.getFileUrlById(fileId);
      }),
      catchError(() => {
        return of(this.getFileUrlById(fileId));
      }),
    );
  }

  private createIntention(scope: string, file: File, meta: Object,type: "support_ticket_screenshot" | null = null): Observable<LectaFileCreateUploadIntentionResult> {
    const params = {
      file: { type: file.type, name: file.name, size: file.size },
      meta,
    };

    if(type){
        params['type'] = type;
    }

    return this.skysmartFileApiService.createIntention(params).pipe(
      map(response => {
        return { type: LectaFileUploadProcessStepResultType.success, response };
      }),
      catchError((error: HttpErrorResponse) => {
        lectaFileEvents.createIntentionError(scope, { params, error });

        return of({ type: LectaFileUploadProcessStepResultType.error, responseCode: error.status });
      }),
    );
  }

  private uploadToAwsDirectly(
    scope: string,
    file: File,
    params: { url: string; form: Object; uuid: string },
  ): Observable<LectaFileAwsUploadResult> {
    const formData = new FormData();
    for (const prop in params.form) {
      formData.append(prop, params.form[prop]);
    }
    formData.append('file', file);

    return this.fileAmazonS3Api.upload(params.url, formData).pipe(
      map(() => {
        return { type: LectaFileUploadProcessStepResultType.success };
      }),
      catchError((error: HttpErrorResponse) => {
        lectaFileEvents.awsUploadError(scope, { params, error });

        return of({ type: LectaFileUploadProcessStepResultType.error });
      }),
    );
  }

  private reportUploadResult(
    scope: string,
    fileUuid: string,
    result: LectaFileUploadProcessStepResultType,
  ): Observable<LectaFileAwsUploadResultResult> {
    return this.reportUploadResultWithRetry(fileUuid, result).pipe(
      map(() => {
        return { type: LectaFileUploadProcessStepResultType.success };
      }),
      catchError((error: HttpErrorResponse) => {
        lectaFileEvents.reportUploadResultError(scope, { fileUuid, error });

        return of({ type: LectaFileUploadProcessStepResultType.error, responseCode: error.status });
      }),
    );
  }

  private reportUploadResultWithRetry(
    fileUuid: string,
    resultType: LectaFileUploadProcessStepResultType,
  ): Observable<void> {
    const retryAttemptsDelaySeconds = [2, 5, 10, 30];

    const result = resultType === LectaFileUploadProcessStepResultType.success ? 'success' : 'error';
    return this.skysmartFileApiService.reportUploadResult({ fileUuid, result }).pipe(
      retryWhen(errors => {
        return errors.pipe(
          mergeMap((error, i) => {
            const retryAttempt = i + 1;
            if (retryAttempt > retryAttemptsDelaySeconds.length) {
              return throwError(error);
            }

            return timer(retryAttemptsDelaySeconds[retryAttempt - 1] * 1000);
          }),
        );
      }),
    );
  }
}
