import deepmerge from 'deepmerge';
import {
  apiSubdomain,
  billingDomain,
  environmentCustom,
  rootDomain,
  skyengDomain,
  cmsDomain,
} from './environment.custom';

export const environment = deepmerge(
  {
    app: {
      production: false,
      buildNumber: 0,
      rootDomain,
      name: 'Lecta Homework',
      educationSubject: 'english',
      skyengDomain,
      commitHash: '',
    },
    endpoints: {
      api: `https://${apiSubdomain}.${rootDomain}`,
      cmsApi: `https://${cmsDomain}.${rootDomain}/`,
      blocksStoreEndpoint: `https://${apiSubdomain}.${rootDomain}`,
      logs: ['https://127.0.0.1'],
      contentVimbox: `https://content-vimbox.${skyengDomain}`,
      serverApi: `https://${apiSubdomain}.${rootDomain}/server-api`,
      webinars:
        'https://uchitel.club/webinars/onlayn-obrazovanie/?utm_source=hw.lecta&utm_medium=topmenu&utm_campaign=hw',
      sendsayForm: 'https://sendsay.ru/form',
      billingDomain: `https://${billingDomain}`,
    },
    contacts: {
      email: 'hw@lecta.ru',
      phone: '+7 (495) 789-30-40',
    },
    limitedAccess: {
      releaseTimestamp: new Date('2022-03-23').getTime(),
    },
    video: {
      enabled: true,
    },
    serverLogger: {
      enabled: false,
    },
    crypto: {
      iv: 'xnMcr9WXXaSnzyph',
      key: 'A2B5EE3A4B73F8FCDA27FA32DA4EDBC8',
    },
    sentry: {
      dsn: '',
    },
    thirdpartyServices: {
      ga: {
        appId: '',
        appIdTwo: ''
      },
      ym: {
        appId: '',
      },
      prosvId: {
        host: 'https://dev-id.prosv.ru',
        consumer: '54a6b532-da17-42e4-9b85-4d2e768c43c4',
      },
      paymentSdk: {
        serviceToken:
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9kZXBhcnRtZW50X2lkIjo3NjI1OCwic3RvcmVfZGVwYXJ0bWVudF9rZXkiOjc2NzY4Nzg3LCJwaW5fY29kZSI6Njc4ODd9.azk_4CScko-L1VZwwSmHNtfd1GZ9ezrFSOJdmKkBESw',
        mode: 'dev',
      },
      vc: {
        login: `https://${billingDomain}/vk/`,
      },
      microSentry:{
        dsn: 'https://62e22502a4834bf3995c9924fc56f6e5@glitchtip-hw-dev.prosv.ru/12'
      }
    },
    tracking: {
      logEvents: false,
      hits: false,
    },
    legal: {
      ofertaUrl: 'https://archive.prosv.ru/_resources/docs/user_manuals/20211130_Dogovor_oferta.pdf',
      ofertaSubcsriptionUrl: 'https://archive.prosv.ru/_resources/docs/user_manuals/20211130_Dogovor_oferta.pdf',
      personalDataAgreementUrl: 'https://archive.prosv.ru/pages/privacy-policy.html',
      agreementUrl: 'https://archive.prosv.ru/_resources/docs/user_manuals/20211130_Polzovatelskoye_soglasheniye.pdf',
      directorsUserManual:
        'https://archive.prosv.ru/_resources/docs/user_manuals/%D0%94%D0%B8%D1%80%D0%B5%D0%BA%D1%82%D0%BE%D1%80-%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE_%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F.pdf',
    },
    instructions: {
      student:
        'https://archive.prosv.ru/_resources/docs/user_manuals/%D0%A3%D1%87%D0%B5%D0%BD%D0%B8%D0%BA-%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE_%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F.pdf',
      teacher:
        'https://archive.prosv.ru/_resources/docs/user_manuals/%D0%A3%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C-%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE_%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F.pdf',
      school_admin:
        'https://archive.prosv.ru/_resources/docs/user_manuals/%D0%94%D0%B8%D1%80%D0%B5%D0%BA%D1%82%D0%BE%D1%80-%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE_%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F.pdf',
    },
  },
  environmentCustom,
);
