import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrentUserService } from '../../../user/core/service/current-user';
import logoImageSrc from '../../core/images/homework-logo.svg';
import {LectaDeviceService} from "@lecta/core/device";

@Component({
  selector: 'app-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLogoComponent {
  @Input() logoLink = '/';
  @Input() target: string;
  @Input() isMainPage: boolean = false;

  isMobile = LectaDeviceService.isMobile();
  logoSvg = logoImageSrc;
  isAnonymousProviderUser$ = this.currentUserService.isAnonymousProviderUser$;

  constructor(private currentUserService: CurrentUserService) {}
}
