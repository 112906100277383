export function getClassMethodName(classObj: Function, method: Function): string | null {
  const methodName = Object.getOwnPropertyNames(classObj).find(prop => classObj[prop] === method);

  if (methodName) {
    return methodName;
  }

  const proto = Object.getPrototypeOf(classObj);
  if (proto) {
    return getClassMethodName(proto, method);
  }

  return null;
}
