import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { animate, group, sequence, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';
import { Notification } from '../../lecta-notification.interface';
import { LectaNotificationService } from 'lecta-ui/notification/services/lecta-notification.service';
import { BreakpointService } from 'lecta-ui/layout';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-main-notifications',
  templateUrl: './lecta-main-notifications.component.html',
  styleUrls: ['./lecta-main-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('notificationItem', [
      transition(':enter', [
        style({ transform: 'translateY(-150px)', height: '0px' }),
        group([
          animate('200ms ease-out', style({ transform: 'translateY(0)' })),
          animate('200ms ease-in-out', style({ height: '*' })),
        ]),
      ]),
      transition(':leave', [
        sequence([
          animate('200ms ease-out', style({ opacity: 0 })),
          animate('200ms ease-in-out', style({ height: '0px' })),
        ]),
      ]),
    ]),
  ],
})
export class LectaMainNotificationsComponent implements OnInit {
  notifications$: Observable<Notification[]>;
  isMobile = this.breakpointService.isSmall();

  constructor(private notificationService: LectaNotificationService, private breakpointService: BreakpointService) {}

  ngOnInit(): void {
    this.notifications$ = this.notificationService.mainNotifications$;
  }

  onClose(notification: Notification): void {
    this.notificationService.closeNotification(notification, 'main');
  }

  onAnimationDone(): void {
    this.notificationService.destroyMainOverlayIfEmpty();
  }
}
