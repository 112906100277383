import { Inject, Injectable } from '@angular/core';
// import { environment } from 'environments/environment';
import { distinctUntilChanged, filter, map, startWith, switchMap, take } from 'rxjs/operators';
import _isEqual from 'lodash/isEqual';
import { CurrentUserService } from 'user/core/service/current-user';
import { isPrerenderMode } from 'core/misc';
import { wrapInObject } from './helpers';
import { UtmKeyValue } from './utm';
import { Store } from 'core/store';
import { TRACKING_TARGETS_PROVIDER, TrackingTargetKey } from '../const';
import { TrackingContext, TrackingTarget } from '../interfaces/tracking.interface';
import { Router } from '@angular/router';
import { lectaTrackingEvents } from 'tracking/utils/tracking-events.utility';
import { RoutingHelpersService } from '@lecta/core/routing';

interface StoreEvents {
  trackedEvent: { event: string; data?: Object };
}

const INITIAL_STORE_VALUE = { context: {} as TrackingContext };

@Injectable({ providedIn: 'root' })
export class TrackingService {
  private inited = false;
  private store = new Store<typeof INITIAL_STORE_VALUE, StoreEvents>(INITIAL_STORE_VALUE);
  trackedEvent$ = this.store.on('trackedEvent');

  constructor(
    private routingHelpersService: RoutingHelpersService,
    private currentUserService: CurrentUserService,
    private router: Router,
    @Inject(TRACKING_TARGETS_PROVIDER) private targets: TrackingTarget[],
  ) {}

  init(): void {
    if (isPrerenderMode() || this.inited) {
      return;
    }

    this.inited = true;
    this.targets.forEach(target => target.init(context => this.updateContext(context)));

    this.routingHelpersService.routeChanged$
      .pipe(take(1))
      .subscribe(() => this.setUserProperty('landing', window.location.href));

    this.currentUserService.authorizedUser$
      .pipe(
        take(1),
        switchMap(() => this.currentUserService.getConfig()),
      )
      .subscribe(config => {
        if (config.features.ourContent) {
          this.setUserProperty('ab_content_group', config.features.ourContent);
        }

        const isTest = config.isTest ? 'true' : 'false';

        this.setUserProperty('isTest', isTest);
      });

    this.currentUserService.features$
      .pipe(
        map(features =>
          // send only sm* prefix features
          Object.keys(features)
            .filter(key => ['smGroup', 'smStepSuggestions'].includes(key))
            .reduce((summ, key) => ({ ...summ, [key]: features[key] }), {}),
        ),
        filter(features => !!Object.keys(features).length),
        distinctUntilChanged((feature1, feature2) => _isEqual(feature1, feature2)),
      )
      .subscribe(features =>
        Object.keys(features).forEach(key => this.setUserProperty(`feature-${key}`, features[key])),
      );

    lectaTrackingEvents.track.listen().subscribe(({ name, data }) => this.trackEvent(name, data));

    this.routingHelpersService.routeChanged$
      .pipe(
        map(({ urlAfterRedirects }) => urlAfterRedirects),
        startWith(this.router.url),
        distinctUntilChanged(),
      )
      .subscribe(currentUrl => {
        this.targets.forEach(target => target.trackPageView(currentUrl));
      });
  }

  /**
   * @description The method sends an event to all trackers
   * @param {string} event - Event's name.
   * @param {unknown} [data] - Event's data.
   * */
  trackEvent(event: string, data?: unknown): void {
    if (!this.inited) {
      return;
    }
    let matchedTargets: TrackingTargetKey[] = [];
    let sentTargets: TrackingTargetKey[] = [];

    const effectiveData = wrapInObject(data);
    const resultData = { ...effectiveData, ...this.getGlobalData() };

    this.targets.forEach(target => {
      const targetKey = target.key;
      if (!target.isEventCanBeTrackedBy(event)) {
        return;
      }

      matchedTargets = [...matchedTargets, targetKey];
      const result = target.trackEvent(event, resultData);
      if (result) {
        sentTargets = [...sentTargets, targetKey];
      }
    });

    // if (environment.tracking.logEvents) {
    //   console.log(
    //     'TrackingService.logEvent:',
    //     { event, data: resultData },
    //     { matched: matchedTargets, sent: sentTargets },
    //   );
    // }

    this.store.fire('trackedEvent', { event, data: resultData });
  }

  setUserProperty(prop: string, value: unknown): void {
    // if (environment.tracking.logEvents) {
    //   console.log('TrackingService.setUserProperty:', { prop, value });
    // }

    this.targets.forEach(target => {
      target.setUserProperty(prop, value);
    });
  }

  setUtms(filledUtms: UtmKeyValue[]): void {
    filledUtms.forEach(({ utmKey, utmValue }) => this.setUserProperty(utmKey, utmValue));
  }

  private getGlobalData(): Object {
    return { ...this.getCurrentContext(), mobileApp: false };
  }

  private updateContext(context: TrackingContext): void {
    this.store.updateFields({ context: { ...this.getCurrentContext(), ...context } });
  }

  private getCurrentContext(): TrackingContext {
    return this.store.get('context');
  }
}
