import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Notification } from '../../lecta-notification.interface';
import { NotificationInstance } from '../../lecta-notification.class';
import { BreakpointService } from 'lecta-ui/layout';
import { updateComponentCssVars } from 'styles/theme/theme.helpers';
import { NOTIFICATION_CSS_VARS_PREFIX, NOTIFICATION_CUSTOMISABLE_PARAMS } from '../../lecta-notification.const';
import { timer } from 'rxjs';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import closeIconSrc from './images/close.svg?raw';

const HIDING_TIMER_MS = 5000;
const XS_BUTTON_HEIGHT_PX = 36;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-notification-item.component',
  templateUrl: './lecta-notification-item.component.html',
  styleUrls: ['./lecta-notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DecorateUntilDestroy()
export class LectaNotificationItemComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() notification: Notification;
  @Output() closeClick = new EventEmitter<void>();

  @ViewChild('root', { static: true }) rootRef: ElementRef<HTMLElement>;
  @ViewChild('body', { static: true }) bodyRef: ElementRef<HTMLElement>;

  isMobile = this.breakpointService.isSmall();
  closeIcon = closeIconSrc;
  bodyDirection: 'vertical' | 'horizontal';

  constructor(
    private renderer2: Renderer2,
    private notificationInstance: NotificationInstance,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointService: BreakpointService,
  ) {}

  ngOnInit(): void {
    this.notification = this.notificationInstance.notification;
    updateComponentCssVars({
      renderer: this.renderer2,
      rootElement: this.rootRef,
      componentPrefix: NOTIFICATION_CSS_VARS_PREFIX,
      params: NOTIFICATION_CUSTOMISABLE_PARAMS,
      type: this.notification.type,
    });

    if (this.notification.autoClosing) {
      timer(HIDING_TIMER_MS)
        .pipe(takeUntilDestroyed(this))
        .subscribe(() => this.onClose());
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    const bodyHeight = this.bodyRef.nativeElement.offsetHeight;
    this.bodyDirection = bodyHeight > XS_BUTTON_HEIGHT_PX ? 'vertical' : 'horizontal';
    this.changeDetectorRef.detectChanges();
  }

  onClose(): void {
    this.notificationInstance.close();
  }
}
