import {ErrorHandler, Injectable} from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: Error): void {
    if (error.message.includes("ChunkLoadError")) {
      Sentry.captureException("RELOADED "+error.message);
      if(sessionStorage.getItem('reloaded') === 'true'){
        sessionStorage.setItem('reloaded', 'true');
        window.location.reload();
      }
    }else{
      const exception = error?.message ?? error;
      Sentry.captureException(exception);
    }
  }
}
