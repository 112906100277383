import { Routes } from '@angular/router';
import { Page404Component } from '../component/page-404/page-404';
import {
  defaultErrorPagesRoutes,
  diariesIntegrationsRoutes,
  homeworkRoutes,
  landingRoutes,
  prosvRoutes,
  studentRoutes,
  teacherRoutes,
  userRoutes,
  cmsRoutes,
} from './route';

/*
 * RelocatePluginComponent заглушка на случай перезда.
 * Для того чтобы подключить заглушку необходимо добавить в Routes первым элементом - { path: '**', component: RelocatePluginComponent },
 * И не забыть импортировать RelocatePluginComponent
 * */
export const routes: Routes = [
  ...homeworkRoutes,
  ...studentRoutes,
  ...teacherRoutes,
  ...userRoutes,
  ...landingRoutes,
  ...diariesIntegrationsRoutes,
  ...defaultErrorPagesRoutes,
  ...prosvRoutes,
  ...cmsRoutes,

  // all missed pages
  { path: '**', component: Page404Component },
];
