import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { updateComponentCssVars } from 'styles/theme/theme.helpers';
import { BUTTON_CSS_VARS_PREFIX, BUTTON_CUSTOMISABLE_PARAMS } from '../../lecta-button.const';
import { SpinnerColor, SpinnerSize } from 'lecta-ui/spinner';
import { ButtonNativeType, ButtonSize } from '../../lecta-button.interface';
import { BreakpointService } from 'lecta-ui/layout';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-button',
  templateUrl: './lecta.button.component.html',
  styleUrls: ['./lecta-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LectaButtonComponent implements OnChanges {
  @Input() round = false;
  @Input() disabled = false;
  @Input() size: ButtonSize = 's';
  @Input() type: string;
  @Input() nativeType?: ButtonNativeType;
  @Input() spinner = false;
  @Input() spinnerColor: SpinnerColor;
  @Input() iconOnly = false;

  @ViewChild('root', { static: true }) rootRef: ElementRef<HTMLElement> | null;

  isMobile = this.breakpointService.isSmall();
  resizeMap: Record<ButtonSize, ButtonSize> = {
    xs: 'xs',
    s: 's',
    m: 's',
    l: 's',
    xl: 'l',
  };

  constructor(private renderer: Renderer2, private breakpointService: BreakpointService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      updateComponentCssVars({
        renderer: this.renderer,
        rootElement: this.rootRef,
        params: BUTTON_CUSTOMISABLE_PARAMS,
        componentPrefix: BUTTON_CSS_VARS_PREFIX,
        type: this.type,
      });
    }
  }

  checkPropagation(event: MouseEvent): void {
    if (this.disabled || this.spinner) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  getSize(): ButtonSize {
    return this.isMobile ? this.resizeMap[this.size] : this.size;
  }

  getSpinnerSize(): SpinnerSize {
    if (['m', 'l', 'xl'].includes(this.size)) {
      return 'm';
    }

    return 's';
  }

  getSpinnerColor(): SpinnerColor {
    return this.spinnerColor ? this.spinnerColor : 'custom';
  }
}
